.press-release {
    &-body {
        &-logo {
            &-body {
                display: flex;
                flex-direction: row;
                &__left-column {
                    width: 20%;
                    min-width: 120px;
                    img {
                        display: block;
                    }
                }
                &__right-column {
                    margin-left: 40px;
                }
            }
        }
    }
}
