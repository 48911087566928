.mgt-xxs { display: none; }
.mgt-xs  { display: none; }
.mgt-sm  { display: none; }
.mgt-md  { display: block; }
.mgt-lg  { display: none; }
.mgt-hd  { display: none; }

#mgt-developer-toolbar {
    background: #c07600;
    color:black;
}
