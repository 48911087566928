.opc-wrapper .shipping-address-item {
    width: 33.33333333%;
}
.opc-wrapper .shipping-address-item:before {
    background: #cccccc;
    height: calc(100% - 20px);
    content: '';
    left: 0;
    position: absolute;
    top: 0;
    width: 1px;
}
.opc-wrapper .shipping-address-item:nth-child(3n + 1):before {
    display: none;
}
.opc-wrapper .shipping-address-item.selected-item:before {
    display: none;
}
.opc-wrapper .shipping-address-item.selected-item + .shipping-address-item:before {
    display: none;
}

.opc-payment {
    width: calc(100% - 110px);
}
