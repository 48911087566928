$actu-gap: 30px;

body.contentmanager-contenttype-homepage {
    .page-main {
        .homepage {
            section {
                + .homepage_actualites {
                    .homepage_actualites-cards {
                        .actu-card-textual-infos {
                            height: calc(100% - ((var(--container-width) - (#{$actu-gap} * 2 )) / 3) / 4 * 3);
                        }

                        .actu0 {
                            min-height: calc(var(--container-width) / 4);
                        }
                    }
                }

                + .homepage_services {
                    .ct-field-repeater_fields {
                        display: grid;
                        grid-auto-columns: 1fr;
                        grid-template-columns: repeat(2, 1fr);
                        grid-template-rows: repeat(4, 1fr);
                        gap: 32px 32px;
                    }

                    .service-block {
                        height: 150px;
                        margin: 16px 0 45px;
                        .service-image {
                            display: block;
                            text-align: center;
                            width: 16.67%;
                            max-width: 50px;
                            margin-right: 24px;
                            margin-left: 0;
                        }

                        .service-texts {
                            width: 83.33%;
                            h2 {
                                margin-bottom: 10px;
                            }

                            p {
                                height: 71px;
                                text-align: justify;
                                margin: 10px 0;
                            }

                        }
                    }
                }

                + .homepage_thematiques {
                    .ct-field-repeater_fields {
                        margin: 0 auto;
                        display: grid;
                        grid-template-rows: 1fr 1fr 1fr;
                        gap: 0;
                        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
                        grid-template-areas:
                        "one one one one one two two two two two"
                        "three three three four four four four five five five"
                        "six six six six six seven seven seven seven seven"
                        "eight eight nine nine nine ten ten ten eleven eleven";
                        .rubrique {
                            &::before,
                            &::after {
                                content: unset;
                            }
                            &:nth-child(1),
                            &:nth-child(3),
                            &:nth-child(6),
                            &:nth-child(10) {
                                padding-left: 0;
                                &::before {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    left: calc(-1 * var(--container-width) / 2 + 0px);
                                    width: calc(var(--container-width) / 2 + 0px);
                                    height: 100%;
                                    background: inherit;
                                }
                                &::after {
                                    content: unset;
                                }
                            }
                            &:nth-child(2),
                            &:nth-child(5),
                            &:nth-child(7),
                            &:nth-child(9),
                            &:nth-child(11) {
                                &::before {
                                    content: unset;
                                }
                                &::after {
                                    content: '';
                                    position: absolute;
                                    top: 0;
                                    right: calc(((var(--container-width) - 2rem) - 100vw) / 2);
                                    width: calc((100vw - (var(--container-width) - 2rem)) / 2);
                                    height: 100%;
                                    background: inherit;
                                }
                            }

                            &:nth-child(1),
                            &:nth-child(6) {
                                padding-right: 2rem;
                            }

                            &:nth-child(2),
                            &:nth-child(7) {
                                padding-left: 25%;
                                padding-right: 2rem;
                            }

                            &:nth-child(3) {
                                padding-right: 20%;
                            }

                            &:nth-child(4),
                            &:nth-child(5) {
                                padding-left: 20%;
                                padding-right: 20%;
                            }

                            &:nth-child(8) {
                                padding-left: 2rem;
                                padding-right: 2rem;
                            }

                            &:nth-child(9) {
                                padding-left: 2rem;;
                                padding-right: 0;
                            }

                            &:nth-child(10) {
                                &::after {
                                    content: unset;
                                }
                            }

                            &:nth-child(11) {
                                padding-left: 20%;
                                padding-right: 20%;
                            }
                        }
                    }
                }

                + .homepage_ctifl {
                    .homepage_ctifl_upper {
                        margin-bottom: 3rem;
                        .ctifl_upper_block {
                            flex-direction: row;
                            justify-content: space-between;
                            .ct-field,
                            .ctifl_image_container {
                                width: 48%;
                                max-width: 550px;
                                font-size: 1rem;
                                text-align: justify;
                                margin: 0;
                            }
                            .ctifl_image_container {
                                margin-right: -60px;
                                padding-bottom: 3rem;
                            }
                        }
                    }

                    .homepage_ctifl_bottom {
                        .floating_logo {
                            top: 183px;
                        }

                        p {
                            font-size: 0.8rem;
                        }
                    }
                }
            }
            .homepage_social_body {
                display: flex;
                flex-direction: row;

                .social-link {
                    padding-top: 1.5rem;
                    position: relative;
                    display: inline-block;

                    @include link-underline($black, 1ch, 2.7rem, 1px);
                    font-size: 1rem;

                    &:hover,
                    &:focus {
                        color: $primary;
                        background-image: linear-gradient($primary, $primary);
                    }
                }
                .homepage_social_first_col {
                    .homepage-youtube {
                        margin-bottom: 0;
                    }
                }
                .homepage_social_second_col {
                    margin: 0 0 0 30px;

                    .twitter-embed {
                        width: 28vw;
                    }
                }
            }
        }
    }
}
