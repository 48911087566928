body.aw-et-product-page,
body.contentmanager-contenttype-actu,
body.contentmanager-contenttype-projet,
body.contentmanager-contenttype-edition {
    font-size: 14px;

    .page-main {
        max-width: $container-md;
    }
    .edition-cover {
        width: 330px;
        height: 468px;
    }
    .edition-data-panel {
        min-width: unset;
        width: 55%;
        .summary {
            .summary-title {
                margin-bottom: 13px;
                font-size: 1.3rem;
            }
            .summary-listing {
                height: 300px;
            }
            .subscribe {
                margin-top: 28px;
            }
        }
    }
    .edition-related {
        &-list {
            &-item {
                &-header {
                    height: 2.2em;
                }
                &-body {
                    &-content {
                        .result-sub-content-data-tags {
                            max-height: 2.5rem;
                        }
                    }
                }
                &.last-related {
                    display: block;
                }
            }
        }
    }
    .actu-article,
    .edition-article {
        &-progress-bar{

        }
        &-header {
            margin-left: calc((-100vw - 40px + #{$container-md}) / 2);
            padding-left: calc((100vw + 40px - #{$container-md}) / 2);
        }
    }
}

.edition-progress-bar {
    top: 111px;
}

.page-wrapper:has(.welcome-wrapper) .edition-progress-bar {
    top: calc(111px + #{$welcome-banner-height});
}

body.aw-et-product-page,
body.contentmanager-contenttype-projet,
body.edition-page {
    .edition__header-image {
        width: 940px;
        height: 235px;
        object-fit: cover;
        margin-bottom: 0;
    }
    .edition-related {
        &-list {
            .subscription-sticker {
                top: calc(300px / 4 * 3);
            }
        }
    }
}

body.aw-et-product-page {
   .edition-related {
        &-list {
            &-item {
                &-body-content {
                    .result-title {
                        font-size: 1.0rem;
                    }
                    .card-link {
                        &:hover,
                        &:focus {
                            background-size: 26% 1px !important;
                        }
                    }
                }
            }
        }
    }
}

$pouet: 30vw;

.edition-publication-container {
    .edition-publication {
        min-height: calc(#{$pouet} / 210 * 297);
        .edition-panel-picture {
            margin-inline: calc(#{$container-md} / 20);
            max-width: $pouet;
            height: calc(#{$pouet} / 210 * 297);
        }
    }
}
