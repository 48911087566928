.cms-no-route {
    .cms-no-route__splash-txt {
        margin-left: calc((100vw - var(--container-width)) / 2 + 10%);
        max-width: calc(var(--container-width) / 2);
    }

    .cms-no-route__desc {
        padding-right: calc((100vw - var(--container-width)) / 2 + 2rem);
    }
}
