body.cms-page-view {
    .pagebuilder-column:first-child {
        &.navigator-column {
            min-width: 280px !important;
            width: auto;
            overflow: unset;
        }
        div {
            .who-are-we-navigator {
                display: block;
            }
        }
    }
    .pagebuilder-column:last-child {
        width: calc(100% - 320px);
        margin-left: 40px;
        margin-right: 0;
    }
}


.pagebuilder-slider .pagebuilder-slide-wrapper {
    max-width: 75%;
    height: calc((#{$container-md} * 0.75 - 2rem) / 4 * 3);
}
