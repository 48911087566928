$card-image-height: 166px;
$card-height: 231px;
$card-width: 311px;

body.search-content-index,
body.algolia-list,
body.algolia-list-actu {
    --algolia-left-container-width: 220px;

    .row {
        .algolia-left-container {
            width: var(--algolia-left-container-width);
            display: block;
            padding: 0;
        }

        .algolia-right-container {
            width: calc(100% - var(--algolia-left-container-width));
            border-left: 1px solid $lighter-grey;
        }
    }

    #algolia_instant_selector {
        max-width: unset;
    }
}

body.search-content-index,
body.algolia-list {
    .row {
        .algolia-right-container {
            .row {
                max-width: unset;
            }

            .ais-Hits-item {
                width: 221.33px;

                .instant-hit-template {
                    width: 221.33px;

                    .result-wrapper {
                        .result {
                            .result-sub-content-img-container {
                                height: $card-image-height;

                                .result-sub-content-img {
                                    height: 100%;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

body.algolia-list-actu {
    --grid-rows-height: 164px;

    .row {
        .algolia-right-container {
            .pull-right {
                display: none;
            }

            .actu-first-page {
                .ais-Hits-list {
                    margin: 0 auto;
                    display: grid;
                    grid-template-columns: 1fr 1fr 1fr;
                    grid-template-rows: var(--grid-rows-height);
                    gap: 19px;
                    grid-template-areas:
                "actu-main actu-main actu-sub1"
                "actu-main actu-main actu-sub2"
                "actu-result1 actu-result1 actu-result1"
                "actu-result2 actu-result2 actu-result2"
                "actu-result3 actu-result3 actu-result3"
                "actu-result4 actu-result4 actu-result4"
                "actu-result5 actu-result5 actu-result5"
                "actu-result6 actu-result6 actu-result6"
                "actu-result7 actu-result7 actu-result7"
                "actu-result8 actu-result8 actu-result8"
                "actu-result9 actu-result9 actu-result9"
                "actu-result10 actu-result10 actu-result10"
                "actu-result11 actu-result11 actu-result11"
                "actu-result12 actu-result12 actu-result12";

                    .ais-Hits-item {
                        margin: 0;
                        height: 100%;
                    }

                    .ais-Hits-item:nth-child(1) {
                        grid-area: actu-main;
                    }

                    .ais-Hits-item:nth-child(2) {
                        grid-area: actu-sub1;
                    }

                    .ais-Hits-item:nth-child(3) {
                        grid-area: actu-sub2;
                    }

                    .ais-Hits-item:nth-child(1),
                    .ais-Hits-item:nth-child(2),
                    .ais-Hits-item:nth-child(3) {
                        color: $white;

                        .instant-hit-template.page-actus {
                            height: 100%;

                            .result-wrapper {
                                height: 100%;

                                .result {
                                    .result-content {
                                        position: relative;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;

                                        .result-title {
                                            font-size: 1.4rem;
                                            overflow: unset;
                                            margin-bottom: 0.5em;
                                            height: unset;
                                        }

                                        .result-date {
                                            font-size: 1em;
                                            line-height: 1em;
                                            height: unset;
                                            color: $white;
                                        }

                                        .result-sub-content {
                                            padding: 0;
                                        }

                                        .result-sub-content-data-tags {
                                            margin: 2rem 0;
                                        }

                                        .card-link {
                                            @include link-underline($white, 2ch, 1.4rem, 1px);
                                            position: unset;
                                            margin: 0;

                                            &:hover,
                                            &:focus {
                                                background-size: 9ch 1px;
                                            }

                                            &::before {
                                                content: '';
                                                width: 100%;
                                                height: 100%;
                                                position: absolute;
                                                top: 0;
                                                left: 0;
                                                cursor: pointer;
                                                z-index: 3;
                                            }
                                        }
                                    }

                                    .actu-card-sticker {
                                        color: $black !important;
                                    }
                                }
                            }
                        }

                        a,
                        a:hover,
                        a:active {
                            color: $white !important;
                            text-decoration: none;
                        }
                    }

                    .ais-Hits-item:nth-child(1) {
                        .instant-hit-template.page-actus {
                            .result-wrapper {
                                .result {
                                    .result-content {
                                        padding: 55px;
                                        min-height: 329px;
                                        position: relative;
                                        height: 100%;
                                    }

                                    .actu-card-sticker {
                                        color: $black !important;
                                    }

                                    .result-title {
                                        font-size: 1.4rem;
                                        overflow: unset;
                                        margin-bottom: 2rem;
                                        height: unset;

                                        .result-date {
                                            font-size: 0.875rem;
                                            line-height: 0.875rem;
                                            height: unset;
                                            color: $white;
                                        }
                                    }

                                    .result-sub-content-data-tags {
                                        margin: 2rem 0;
                                    }
                                }
                            }
                        }
                    }

                    .ais-Hits-item:nth-child(2),
                    .ais-Hits-item:nth-child(3) {
                        height: var(--grid-rows-height);

                        .instant-hit-template.page-actus {
                            .result-wrapper {
                                .result {
                                    .result-content {
                                        padding: 27px;
                                        width: 100%;

                                        .result-sub-content {
                                            height: 100%;
                                        }
                                    }

                                    .actu-card-sticker {
                                        color: $black !important;
                                    }

                                    .result-sub-content-data {
                                        height: 100%;

                                        .result-sub-content-data-header {
                                            height: 100%;

                                            .result-title {
                                                display: flex;
                                                flex-direction: column;
                                                justify-content: space-between;
                                                height: 100%;
                                                font-size: 1em;
                                                overflow: unset;
                                                margin-bottom: 1rem;

                                                .result-date {
                                                    color: $white;
                                                }
                                            }
                                        }
                                    }

                                    .result-sub-content-data-tags,
                                    .result-bodytext {
                                        display: none !important;
                                    }

                                    .actu-card-sticker {
                                        right: 0;
                                        left: unset;
                                        transform: unset;
                                    }
                                }
                            }
                        }
                    }
                    @for $i from 4 through 15 {
                        .ais-Hits-item:nth-child(#{$i}) {
                            grid-area: actu-result#{$i - 3};
                            height: $card-height;
                            .instant-hit-template.page-actus {
                                height: $card-height;
                                .result-wrapper {
                                    .result {
                                        .result-sub-content-img-container {
                                            height: $card-height;
                                            width: $card-width;
                                        }
                                    }
                                    .result-content {
                                        width: calc(100% - #{$card-width});
                                        height: $card-height;
                                        .result-sub-content {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            .actu-not-first-page {
                .ais-Hits-list {
                    grid-template-areas:
                        "actu-result1 actu-result1 actu-result1"
                        "actu-result2 actu-result2 actu-result2"
                        "actu-result3 actu-result3 actu-result3"
                        "actu-result4 actu-result4 actu-result4"
                        "actu-result5 actu-result5 actu-result5"
                        "actu-result6 actu-result6 actu-result6"
                        "actu-result7 actu-result7 actu-result7"
                        "actu-result8 actu-result8 actu-result8"
                        "actu-result9 actu-result9 actu-result9"
                        "actu-result10 actu-result10 actu-result10"
                        "actu-result11 actu-result11 actu-result11"
                        "actu-result12 actu-result12 actu-result12"
                        "actu-result13 actu-result13 actu-result13"
                        "actu-result14 actu-result14 actu-result14"
                        "actu-result15 actu-result15 actu-result15"
                    ;
                    .ais-Hits-item {
                        width: 100%;
                        margin: 21px auto;
                        max-width: 985px;
                    }

                    @for $i from 1 through 15 {
                        .ais-Hits-item:nth-child(#{$i}) {
                            grid-area: actu-result#{$i};
                            height: $card-height;
                            .instant-hit-template.page-actus {
                                .result-wrapper {
                                    .result {
                                        .result-sub-content-img-container {
                                            height: $card-height;
                                            width: $card-width;
                                        }
                                    }
                                    .result-content {
                                        width: calc(100% - #{$card-width});
                                        height: $card-height;
                                        .result-sub-content {
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
