.account {
    // Remettre le menu à gauche
    .columns {
        display: block;
    }

    .account-nav-content {
        border-bottom: none;
    }

    .sidebar-main {
        width: 22.3% !important;
    }

    .column.main {
        width: 77.7% !important;

        .block {
            .block-title {
                margin: 2rem 0;
            }

            &.block-dashboard-info,
            &.block-dashboard-addresses,
            &.block-addresses-default {
                .block-content {
                    flex-direction: row;
                    padding-bottom: 2rem;
                }
            }
        }
    }

    .table-order-items {
        .item-options {
            text-align: left;
        }

        .col .cart-price {
            font-size: 1rem;
        }
    }
}
