.aw-et-product-page {
    .aw-et-view-options-wrapper {
        .options-container {
            .attendee-element {
                .attendee-element__header {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: 0;
                }

                .attendee-element__remove {
                    margin-left: 1rem;
                }
            }
        }
    }
}
