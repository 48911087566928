body.contentmanager-contenttype-projet {
    .project {
        //TODO uniformiser avec banner-heading ?
        &__image {
            height: 300px;
        }
        &__body {
            margin-top: 4rem;
            &__left-column {
                display: block;
                width: 280px;
                min-width: 280px;
                max-width: 280px;
                height: 365px;
                position: sticky;
                margin-bottom: 2rem;
                top: 150px;
                &__top-data {
                    padding: 15px 15px 22px;
                    background: $lightest-grey;
                    font-size: 0.85rem;
                    &__label {
                        font-size: 1rem;
                        font-weight: 700;
                        &:not(:first-child) {
                            margin-top: 1.5rem;
                        }
                    }
                    &__data {
                    }
                    li.author {
                        list-style: none;
                    }
                    .icon-website {
                        margin-top: 1.5rem;
                        &::before {
                            margin-right: 0.5rem;
                        }
                        > a {
                            @include link-underline($primary, 2ch, 5px, 1px);
                            height: calc(1rem + 5px);
                            display: inline-block;
                        }
                    }

                }
                &__bottom-data {
                    background: $lightest-grey-nearly;
                    color: $black;
                    font-size: 1rem;
                    &-footer {
                        li {
                            padding: 10px;
                            width: 100%;
                            height: 100%;
                            display: flex;
                            justify-content: center;
                            margin-bottom: 0;
                            position: relative;
                            &:not(:first-child)::before {
                                content: '';
                                position: absolute;
                                width: 1px;
                                height: 50%;
                                left: 0;
                                background-color: #272727;
                            }
                            a {
                                font-size: 0.75rem;
                                font-family: $roboto;
                                color: $black;
                                &:hover {
                                    font-weight: 700;
                                }
                                &::before {
                                    color: $black;
                                    margin-right: 1rem;
                                    font-size: 120%;
                                    transform: translateY(-30%);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
